// Imports => React
import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	PARAMETERS,
	PERMISSIONS,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
} from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsBoolean } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcToggleInput from '@atoms/ac-toggle-input/ac-toggle-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-hammer-type-detail-parameters-tab',
};

let _delay = null;

const AcHammerTypeDetailParametersTab = ({
	data,
	store: { hammer_types, ui },
}) => {
	const { can, cannot } = usePermissions();

	const getRawFields = (d) => {
		if (!AcIsSet(d) || !AcIsSet(d.parameters)) return [];

		const collection = d.parameters || [];
		const len = collection.length;
		let n = 0;
		let result = {};

		for (n; n < len; n++) {
			const item = collection[n];

			if (item.type === TYPES.BOOLEAN) {
				if (AcIsBoolean(item.value)) result[item.name] = item.value ? 1 : 0;
				else result[item.name] = parseInt(item.value, 10);
			} else {
				result[item.name] = `${item.value}`;
			}
		}

		return result;
	};

	const raw_fields = getRawFields(data);

	const [fields, setFields] = useState(raw_fields);

	const { handleInputChange, handleValidaton } = useFormActions({
		fields,
		setFields,
	});

	const init = () => {
		const new_raw_fields = getRawFields(data);
		setFields(new_raw_fields);
	};

	const handleUndoChanges = (event) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (!hasMadeChanges) return;

		hammer_types.get_by_id(data.id);
	};

	const handleFormSubmit = (event) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (!hasMadeChanges) return;

		if (AcIsSet(data) && AcIsSet(fields)) {
			const list = data.parameters;
			const len = list.length;
			let n = 0;
			let parameters = [];

			for (n; n < len; n++) {
				const item = list[n];
				let value = fields[item.name];

				if (item.type === TYPES.BOOLEAN) {
					value = value ? 1 : 0;
				}

				const object = {
					...item,
					value,
				};

				parameters.push(object);
			}

			const payload = { ...data, name: data?.type, parameters };

			hammer_types
				.update(data.id, { ...payload })
				.then(init)
				.catch((error) => {
					hammer_types.get_by_id(data.id);
				});
		}
	};

	const getBasicParameterInputOptions = (setting) => {
		return {
			type: TYPES.TEXT,
			validation: handleValidaton,
			disabled:
				cannot(PERMISSIONS.EQUIPMENT.MANAGE_PARAMETERS) || hammer_types.is_busy,
			callback: handleInputChange,
		};
	};

	const getBasicParameterToggleOptions = ({ name }) => {
		const checked = Boolean(Number(fields[name]));

		return {
			type: TYPES.ON_OFF,
			disabled:
				cannot(PERMISSIONS.EQUIPMENT.MANAGE_PARAMETERS) || hammer_types.is_busy,
			value: 1,
			checked,
			callback: handleInputChange,
		};
	};

	const renderValveParameters = useCallback(
		(key) => {
			if (!AcIsSet(data) || !AcIsSet(data.parameters)) return null;
			if (!AcIsSet(key)) return null;

			const title = TITLES.PARAMS[key.toUpperCase()];
			const { list } = PARAMETERS.HAMMER[key];

			const collection = data.parameters;
			const len = collection.length;
			let g = 0;
			let a = 0;
			let n = 0;
			let grouped = {};
			let result = [];

			const mapping = {
				Slewrate: 'Slew Rate',
				PeakTime: 'Peak Time',
				PeakCurrent: 'Peak Current',
				HoldCurrent: 'Hold Current',
			};

			const firstrow = (
				<AcRow className={'ac-parameters-row'}>
					<AcColumn
						xs={12}
						sm={6}
						className={'ac-parameters-column-5'}
						key={`ac-parameter-label-heading-1`}
					>
						<AcHeading rank={7}></AcHeading>
					</AcColumn>

					<AcColumn
						xs={12}
						sm={6}
						className={'ac-parameters-column-5'}
						key={`ac-parameter-label-heading-2`}
					>
						<AcHeading rank={7}>Hold Current</AcHeading>
					</AcColumn>

					<AcColumn
						xs={12}
						sm={6}
						className={'ac-parameters-column-5'}
						key={`ac-parameter-label-heading-3`}
					>
						<AcHeading rank={7}>Peak Current</AcHeading>
					</AcColumn>

					<AcColumn
						xs={12}
						sm={6}
						className={'ac-parameters-column-5'}
						key={`ac-parameter-label-heading-4`}
					>
						<AcHeading rank={7}>Peak Time</AcHeading>
					</AcColumn>

					<AcColumn
						xs={12}
						sm={6}
						className={'ac-parameters-column-5'}
						key={`ac-parameter-label-heading-5`}
					>
						<AcHeading rank={7}>Slew Rate</AcHeading>
					</AcColumn>
				</AcRow>
			);

			result.push(firstrow);

			for (a; a < len; a++) {
				const obj = collection[a];

				if (obj.name.toLowerCase().indexOf('valve') === -1) {
					continue;
				}

				const raw = obj.name;
				const name = obj.name.replace(/valve/gi, '');
				const arr = name.split('_');
				const key = arr[0];
				const group = arr[1];

				grouped[key] = { key, options: [] };
			}

			for (g; g < len; g++) {
				const item = collection[g];
				const raw = item.name;
				const name = item.name.replace(/valve/gi, '');
				const arr = name.split('_');
				const key = arr[0];
				const group = arr[1];

				if (grouped[key]?.options) {
					grouped[key].options.push(item);
				}
			}

			for (let k in grouped) {
				const group = grouped[k];
				const groupName = grouped[k].key;
				const options = group.options;

				const parsed = options.map((item) => {
					const { name, value, type } = item;

					let options = getBasicParameterInputOptions(item);

					options = {
						...options,
						value: AcIsSet(value) && value !== 'null' ? value : 0,
						label: null,
						name: name,
					};

					let object = null;

					object = (
						<AcColumn
							xs={12}
							sm={6}
							className={'ac-parameters-column-5'}
							key={`ac-parameter-${name}-${value}`}
						>
							<AcTextInput {...options} />
						</AcColumn>
					);

					return object;
				});

				const row = (
					<AcRow className={'ac-parameters-row'}>
						<AcColumn
							xs={12}
							sm={6}
							className={'ac-parameters-column-5'}
							key={`ac-parameter-label-group-name-${groupName}`}
						>
							<AcHeading rank={7}>Valve {groupName}</AcHeading>
						</AcColumn>

						{parsed}
					</AcRow>
				);

				result.push(row);
			}

			if (AcIsSet(result) && result.length > 0) {
				result = (
					<AcRow className={'h-margin-bottom-25'}>
						<AcColumn xs={12}>
							<AcHeading tag={'h2'} rank={6} className={'h-margin-bottom-0'}>
								{title}
							</AcHeading>
						</AcColumn>

						<AcColumn xs={12}>{result}</AcColumn>
					</AcRow>
				);
			}

			return result;
		},
		[data, fields, hammer_types.is_busy]
	);

	const renderParameters = useCallback(
		(key) => {
			if (!AcIsSet(data) || !AcIsSet(data.parameters)) return null;
			if (!AcIsSet(key)) return null;

			const title = TITLES.PARAMS[key.toUpperCase()];
			const { list } = PARAMETERS.HAMMER[key];

			const collection = data.parameters;
			const len = collection.length;
			let n = 0;
			let result = [];

			for (n; n < len; n++) {
				const item = collection[n];

				const { name, value, type } = item;
				const isBoolean = type === TYPES.BOOLEAN;

				if (list.indexOf(name) === -1) continue;

				let options = isBoolean
					? getBasicParameterToggleOptions(item)
					: getBasicParameterInputOptions(item);

				options = {
					...options,
					value:
						!isBoolean && AcIsSet(value) && value !== 'null'
							? value
							: isBoolean
							? value
							: 0,
					label: name,
					name,
				};

				let object = null;

				if (isBoolean) {
					object = (
						<AcColumn xs={12} sm={12} key={`ac-parameter-${name}-${value}`}>
							<AcToggleInput {...options}>
								<span
									dangerouslySetInnerHTML={{
										__html: options.label,
									}}
								/>
							</AcToggleInput>
						</AcColumn>
					);
				} else {
					object = (
						<AcColumn
							xs={12}
							sm={6}
							md={4}
							key={`ac-parameter-${name}-${value}`}
						>
							<AcTextInput {...options} />
						</AcColumn>
					);
				}

				if (object) result.push(object);
			}

			if (AcIsSet(result) && result.length > 0) {
				result = (
					<AcRow className={'h-margin-bottom-25'}>
						<AcColumn xs={12}>
							<AcHeading tag={'h2'} rank={6} className={'h-margin-bottom-0'}>
								{title}
							</AcHeading>
						</AcColumn>

						<AcColumn xs={12}>
							<AcRow>{result}</AcRow>
						</AcColumn>
					</AcRow>
				);
			}

			return result;
		},
		[data, fields, hammer_types.is_busy]
	);

	const hasMadeChanges = useMemo(() => {
		if (!data) return false;
		const collection = Object.assign({}, getRawFields(data));
		const output = Object.assign({}, fields);

		let key = null;
		let result = false;

		for (key in collection) {
			if (AcIsSet(output[key])) {
				const valA = collection[key];
				const valB = output[key];

				if (valA != valB) {
					result = true;
					break;
				}
			}
		}

		return result;
	}, [data, fields]);

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hammer_types.is_busy || !hasMadeChanges,
			loading: hammer_types.is_busy,
			title: 'Save changes',
			callback: handleFormSubmit,
		};
	}, [data, fields, hasMadeChanges, hammer_types.is_busy, handleFormSubmit]);

	const getUndoButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			disabled: hammer_types.is_busy || !hasMadeChanges,
			title: 'Undo changes',
			callback: handleUndoChanges,
			className: 'h-margin-right-25',
		};
	}, [hasMadeChanges, hammer_types.is_busy, handleUndoChanges]);

	const showHideDrawer = (state) => {
		if (!state) {
			ui.setValue(KEYS.DRAWER, KEYS.VISIBLE, false);
		} else {
			ui.set(KEYS.DRAWER, {
				id: AcUUID(),
				visible: true,
				children: (
					<AcContainer>
						<AcRow>
							<AcColumn xs={12} className={'h-flex-h-align-end'}>
								<AcButton {...getUndoButtonOptions}>
									<span>Undo</span>
								</AcButton>

								<AcButton {...getSubmitButtonOptions}>
									<span>Save changes</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				),
			});
		}
	};

	useEffect(() => {
		showHideDrawer(hasMadeChanges);
	}, [fields, hasMadeChanges, hammer_types.is_busy]);

	useEffect(() => {
		return () => ui.reset(KEYS.DRAWER);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	});

	return (
		<div className={getMainClassNames}>
			{!AcIsSet(data) ||
				!AcIsSet(data.parameters) ||
				(data.parameters.length === 0 && (
					<AcEmptyBlock message={'No parameters found to display.'} />
				))}
			{AcIsSet(data) &&
				AcIsSet(data.parameters) &&
				data.parameters.length > 0 && (
					<form method={'post'} onSubmit={handleFormSubmit}>
						<AcContainer fluid>
							<AcRow className={'h-margin-bottom-45'}>
								<AcColumn xs={12} sm={7} md={8}>
									<AcCard flat>
										{renderParameters(KEYS.TIMING)}
										{renderValveParameters(KEYS.VALVE)}
										{renderParameters(KEYS.OTHER)}
									</AcCard>
								</AcColumn>

								<AcColumn xs={12} sm={7} md={4}>
									<AcCard flat>{renderParameters(KEYS.OPTIONS)}</AcCard>
								</AcColumn>
							</AcRow>
						</AcContainer>
					</form>
				)}
		</div>
	);
};

export default withStore(observer(AcHammerTypeDetailParametersTab));
